exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-catalog-catalog-yaml-parent-file-name-js": () => import("./../../../src/pages/catalog/{CatalogYaml.parent__(File)__name}.js" /* webpackChunkName: "component---src-pages-catalog-catalog-yaml-parent-file-name-js" */),
  "component---src-pages-catalog-js": () => import("./../../../src/pages/catalog.js" /* webpackChunkName: "component---src-pages-catalog-js" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-framework-documentation-documentation-yaml-parent-file-name-js": () => import("./../../../src/pages/framework/documentation/{DocumentationYaml.parent__(File)__name}.js" /* webpackChunkName: "component---src-pages-framework-documentation-documentation-yaml-parent-file-name-js" */),
  "component---src-pages-framework-js": () => import("./../../../src/pages/framework.js" /* webpackChunkName: "component---src-pages-framework-js" */),
  "component---src-pages-framework-tutorial-tutorial-yaml-parent-file-name-js": () => import("./../../../src/pages/framework/tutorial/{TutorialYaml.parent__(File)__name}.js" /* webpackChunkName: "component---src-pages-framework-tutorial-tutorial-yaml-parent-file-name-js" */),
  "component---src-pages-guides-guides-yaml-base-guides-yaml-parent-file-name-js": () => import("./../../../src/pages/guides/{GuidesYaml.base}-{GuidesYaml.parent__(File)__name}.js" /* webpackChunkName: "component---src-pages-guides-guides-yaml-base-guides-yaml-parent-file-name-js" */),
  "component---src-pages-guides-js": () => import("./../../../src/pages/guides.js" /* webpackChunkName: "component---src-pages-guides-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */)
}

